import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { skip } from 'rxjs';
import {
  MatAutocomplete,
  MatAutocompleteOrigin,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  AvatarComponent,
  FlexGapDirective,
  FlexLayoutAlignDirective,
  IconPathPipe,
  SearchMarkerUnderlinePipe,
} from 'ngx-q360-lib';
import { SvgIconComponent } from 'angular-svg-icon';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatLabel } from '@angular/material/form-field';

interface IOptions {
  id: string;
  name?: string;
  email: string;
  photoUrl?: string;
}

@Component({
  selector: 'app-select-auto-complete-users-async',
  templateUrl: './select-auto-complete-users-async.component.html',
  styleUrls: ['./select-auto-complete-users-async.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutoCompleteUsersAsyncComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FlexGapDirective,
    MatLabel,
    MatAutocompleteOrigin,
    FlexLayoutAlignDirective,
    AvatarComponent,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    MatOption,
    FlexModule,
    SvgIconComponent,
    SearchMarkerUnderlinePipe,
    IconPathPipe,
  ],
})
export class SelectAutoCompleteUsersAsyncComponent implements OnInit, AfterViewInit {
  options = input.required<IOptions[]>();
  selectedOption = signal<IOptions | undefined>(undefined);
  loading = input.required<boolean>();
  itemName = input('');
  placeholder = input('');
  focusOnStart = input(false);
  label = input<string | undefined>(undefined);
  searchLabel = input<string>('Start typing to search');
  enableNewItem = input(false);
  displayEmail = input(true);
  inFocus = signal<boolean>(false);
  sasUrl = input.required<URL | null>();

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  private readonly destroyRef = inject(DestroyRef);
  private readonly cd = inject(ChangeDetectorRef);
  searchControl = new FormControl();

  value = signal<string | null>(null);

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), debounceTime(300), skip(1))
      .subscribe((value) => {
        if (!value) {
          this.selectedOption.set(undefined);
          this.writeValue(value);
          this.onChange(value);
          this.onTouched(value);
        }
        this.valueChange.emit(value);
      });
  }

  ngAfterViewInit() {
    if (this.focusOnStart()) {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }
  }

  get emailLocalPart() {
    const split = this.searchControl.value ? this.searchControl.value.split('@') : '';
    return split.length ? split[0] : this.searchControl.value;
  }

  get emailDomain() {
    const split = this.searchControl.value ? this.searchControl.value.split('@') : '';
    return split.length === 2 ? split[1] : this.searchControl.value;
  }

  writeValue(value: string) {
    this.value.set(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
    this.cd.markForCheck();
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.writeValue(event.option.value);
    this.onChange(event.option.value);
    this.onTouched(event.option.value);
    this.selectedOption.set(this.options().find((o) => o.id === this.value()));
  }

  onBlur() {
    this.onClosed();
    this.inFocus.set(false);
  }

  onClosed() {
    if (!this.displayEmail()) {
      return;
    }

    const checkExistingMail = this.options().find((o) => o.email === this.searchControl.value);
    if (checkExistingMail) {
      this.selectedOption.set(checkExistingMail);
      this.onChange(checkExistingMail.id);
      this.onTouched(checkExistingMail.id);
      this.writeValue(checkExistingMail.id);
    } else {
      this.selectedOption.set({ id: this.searchControl.value, email: this.searchControl.value });
      this.onChange(this.searchControl.value);
      this.onTouched(this.searchControl.value);
      this.writeValue(this.searchControl.value);
    }
  }

  onOpened() {
    this.inFocus.set(true);
  }

  onFocus() {
    this.onTouched();
    this.inFocus.set(true);

    const existingValue = this.selectedOption();
    if (existingValue) {
      this.searchControl.setValue(this.displayEmail() ? existingValue.email : existingValue.name);
    }
  }

  onOptionClick() {
    this.searchInput.nativeElement.blur();
  }
}
